<template>
  <div class="nao-club">
    <div class="container pb-5">
      <div class="row text-center">
        <div class="col-12">
          <img src="../assets/NAO/logo-nao-con-lineas.svg" alt="logo" class="mx-auto mt-3">
          <div class="mt-5 h3">
            Reconocemos, impulsamos y premiamos tu gran labor <br>
            Reserva con nosotros y gana hasta $12,000 MN al mes*<br>
            Adicionales a las promociones con socios comerciales
          </div>
          <p class="mt-4">Para empezar a ganar con Nao Club sigue estos pasos:</p>
        </div>
        <div class="col-12 col-md-6">
          <ol>
            <li> Inscríbete en el programa 
            <div class="text-center">
              <a class="btn btn-secondary btn-accion mt-3" href="https://es.surveymonkey.com/r/naoclub" target="_blank">Inscríbete aquí</a>
            </div>
            </li>
            <li>Un ejecutivo de ventas de NAO Travel Collection confirmará tu registro al programa NAO CLUB </li>
            <li>Registraremos tus ventas mensuales y entre los días 15 y 20 del mes siguiente te enviaremos un correo para confirmarte el monto que ganaste</li>
          </ol>
        </div>
        <div class="col-12 col-md-6 bloque-2-pasos">
          <ol start="4">
            <li> 
              El monto en pesos que hayas obtenido se convierte en Puntos que podrás canjear por certificados
            </li>

            <li>
              Para conocer tu saldo y canjear tus puntos disponibles
              <div class="text-center">
              <a class="btn btn-secondary btn-accion my-2" href="https://travelcollection.ohgiftcard.com" target="_blank">haz click aquí</a>
              </div>
              <div class="text-center text-md-left mt-3 mt-md-0">
                En caso de no contar con tu usuario y contraseña envíanos un correo a
              <a href="mailto:ventas@naotravelco.com">ventas@naotravelco.com</a>
              </div>
            </li>
          </ol>
        </div>

      </div><!-- row-->
    </div><!-- container-->

    <div class="container">
      <div class="row">
        <div class="col mb-5">
          <p class="h2 text-center titulo-canje">Podrás canjear tus puntos por certificados de marcas como:</p>
        </div>
      </div>

      <div class="d-flex fila-marcas">
        <div class="marca"><img class="img-marca" src="../assets/NAO/club/amazon.png"></div>
        <div class="marca"><img class="img-marca" src="../assets/NAO/club/apple-music.png"></div>
        <div class="marca"><img class="img-marca" src="../assets/NAO/club/starbucks.png"></div>
        <div class="marca"><img class="img-marca" src="../assets/NAO/club/liverpool.png"></div>
        <div class="marca"><img class="img-marca" src="../assets/NAO/club/uber.png"></div>
      </div>
      
      <div class="d-flex fila-marcas fila-marcas-2">
        <div class="marca"><img class="img-marca" src="../assets/NAO/club/spotify.png"></div>
        <div class="marca"><img class="img-marca" src="../assets/NAO/club/cinepolis.png"></div>
        <div class="marca"><img class="img-marca" src="../assets/NAO/club/gandhi.png"></div>
        <div class="marca"><span class="otras-marcas">...entre&nbsp;otras</span></div>
      </div>

      <div class="row mb-5">
        <div class="col">
          <p class="text-justify mt-5 text-condiciones">
            Aplica para cualquier venta confirmada y pagada, excepto vuelos y servicios adicionales en la reserva del crucero, tales como excursiones operadas por
            la naviera, paquetes de bebidas o paquetes de internet. Para el cálculo de Puntos se tomará un tipo de cambio fijo de $20 MN por cada $1 USD. Los
            puntos que adquiriste no tienen caducidad excepto cuando los redimas por algún certificado, en cuyo caso caducarán después de un tiempo de
            acuerdo con el certificado adquirido Los ganadores recibirán acceso al sistema con un usuario y contraseña personalizado y podrán redimir los montos
            en M.N. por certificados de compra con marcas como Amazon, Spotify, Itunes, EnviaFlores.com, Uber, LIiverpool, entre otras. En caso de reservaciones
            canceladas se descontará el monto del premio sobre el monto de ventas equivalente en los siguientes meses durante la vigencia del programa en caso
            de que las hubiera. Las ventas se contabilizarán en montos antes de impuestos, únicamente en servicios de viaje comisionables y no aplica en vuelos.
            Nao Club es un programa de incentivos para premiar la lealtad de los agentes de viajes que reservan los servicios de viaje que comercializa NAO CRUISES
            SAPI de CV, y no genera relación contractual alguna con los agentes. Los Puntos NAO CLUB no podrán canjearse por dinero en efectivo, y solo podrán
            redimirse por lasrecompensas que NAO Travel Collection ponga a disposición de los agentes registrados en su sitio
            <a href="https://travelcollection.ohgiftcard.com" target="_blank">https://travelcollection.ohgiftcard.com</a>
            
          </p>

        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  components: {  },
  data() {
    return {

    };
  },
  async mounted() {
    this.$isLoading(true)
  },
  methods: {
    scrollFix: function(hash) {
      setTimeout(() => {
        const el = document.querySelector('#'+hash)
        el && el.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }, 2000);
      // setTimeout(() => $('html, body').animate({
        
      // scrollTop: $('#'+hash).offset().top
      // }, 1000), 1)
    },        
    goto(url){
      if(url.includes('http')){
        console.log('web', url)
        window.open(url)
      }
      else{
        window.location.href =url
        console.log('in', url)
      }      
      // console.log('goto ruta: ', rutaWeb)
      // this.$router.push(rutaWeb)
    },    

    
  },
};
</script>
<style scoped lang="scss">

  $custom-size: 1.4rem;
  $custom-size-desktop: 1rem;
  
  .nao-club{
    font-size: $custom-size;
    font-weight: 400;
  }
  
  p{
    font-size: $custom-size !important;
  }

  * {
    color: #585B5D; 
  }

  a.btn-accion{
    font-weight: 600;
    font-family: sans-serif !important;
    color: white !important;
    text-transform: uppercase;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    background-color: #585B5D;
    border-color: #585B5D;
  }

  ol{
    padding-left: 4rem;

    & > li::marker {
      font-size: 3rem;
      font-weight: bold;
      color: #b9b9b9;
      text-align: left;
    }

    &>li{
      text-align: justify;
    }
  }



  .titulo-nivel{
    letter-spacing: 1px;
    font-weight: bold;
    font-size: 1.4rem !important;
    font-family: serif !important;
  }

.img-marca{
  max-width: 100%;
}


.fila-marcas{
  justify-content: space-between;
  .marca{
    width: 18%;
  }
  
}


.fila-marcas-2{
  padding-left: 10%;
  padding-right: 10%;
  margin-top: 0.8em;
}

.text-condiciones{
  font-size: 12px !important;
}



  @media (min-width: 768px)
  {
    .nao-club{
      font-size: $custom-size-desktop;
    }
      
    p{
      font-size: $custom-size-desktop !important;
    }

    .fila-marcas{
      justify-content: space-between;
      .marca{
        width: 15%;
      }
      
    }
    
    
    .bloque-2-pasos{
      border-left: 1px solid lightgray;
    }

  }

</style>